<template>
  <div>
    <content-template
      :menuName="`${
        inputType == 'Edit' ? $t('general.edit') : $t('general.new')
      } ${$t('expert.expertMatching')}`"
      :backButton="true"
      @backToPage="moveToOther('Expert Matching Dashboard')"
    >
      <template #form>
        <form>
          <Input
            v-model="edited_item.title"
            :label="$t('expertMatching.projectTitle')"
            class="s-mb-20"
            :error="validation.title"
          />
          <Input
            v-model="edited_item.location"
            :label="$t('cfcComment.location')"
            class="s-mb-20"
            :error="validation.location"
          />

          <Textarea
            v-model="edited_item.description"
            :label="$t('general.description')"
            class="s-mb-20"
            :error="validation.description"
          />
          <Input
            v-model="edited_item.start_date"
            type="date"
            :label="$t('APMA.startDate')"
            class="s-mb-20"
            :error="validation.start_date"
          />
          <Input
            v-model="edited_item.end_date"
            type="date"
            :label="$t('APMA.endDate')"
            class="s-mb-20"
            :error="validation.end_date"
          />

          <div class="s-mb-20">
            <label class="s-input-label">{{ $t("expert.expertise") }}</label>
            <Multiselect
              v-model="edited_item.expertise_detail"
              :tag-placeholder="`${$t('general.addThisNew')} ${$t(
                'sidebar.expertise'
              )}`"
              :placeholder="`${$t('general.searchOrAdd')} ${$t(
                'sidebar.expertise'
              )}`"
              label="name"
              :options="expertise_dropdown.map((type) => type.id)"
              :custom-label="
                (opt) => expertise_dropdown.find((x) => x.id == opt).name
              "
              :multiple="true"
            ></Multiselect>
            <span class="s-input-error" v-if="validation.expertise_detail">
              {{ $t("general.fillThisField") }}
            </span>
          </div>
        </form>
        <div class="s-mt-20 s-flex">
          <div class="s-ml-auto" v-if="inputType != 'Detail'">
            <Button color="error" @click="cancelForm()">
              {{ $t("general.cancel") }}
            </Button>
            <Button
              class="s-ml-20"
              @click="onFinish()"
              :isLoading="isLoading"
              >{{ $t("general.submit") }}</Button
            >
          </div>
          <div v-else class="s-ml-auto">
            <Button @click="onSignUpRequest"> Sign Up </Button>
          </div>
        </div>
      </template>
    </content-template>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import form from "@/mixins/form";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ContentTemplate },
  props: ["inputType", "item"],
  mixins: [form],
  data() {
    return {
      edited_item: {
        title: "",
        location: "",
        description: "",
        start_date: "",
        end_date: "",
        expertise_detail: [],
      },

      validation: {
        title: false,
        location: false,
        description: false,
        start_date: false,
        end_date: false,
        expertise_detail: false,
      },
      isLoading: false,
    };
  },

  computed: {
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    ...mapGetters({
      expertise_dropdown: "expertise/getExpertise",
      expert_matching_data: "expertmatching/getExpertMatching",
    }),
  },
  mounted() {
    this.initData();
  },
  methods: {
    ...mapActions({
      expert_matching: "expertmatching/postExpertMatchingData",
      setExpertise: "expertise/setExpertiseDropdownData",
      setExpertMatchingSingleData: "expertmatching/setExpertMatchingSingleData",
      signUpExpertMatching: "expertmatching/signUpExpertMatching",
      expert_matching_update: "expertmatching/updateExpertMatchingData",
    }),
    async initData() {
      this.setLoadingPage(true);
      await this.setExpertise();
      if (this.inputType == "Edit") {
        this.getEditedData();
      }
      this.setLoadingPage(false);
    },

    importData(id) {
      const fileInput = this.$refs[id];
      const fileUpload = fileInput.files[0];
      const fsize = fileUpload.size;
      const file = Math.round(fsize / 1024);

      if (file <= 5000) this.fileDoc = fileUpload;
    },
    attachImage(id) {
      document.getElementById(id).click();
    },

    async getEditedData() {
      if (this.inputType == "Edit" || this.inputType == "Detail") {
        await this.setExpertMatchingSingleData(this.$route.params.id);

        //this.edited_item = Object.assign({}, this.expert_matching_data);
        this.edited_item.title = this.expert_matching_data.title;
        this.edited_item.location = this.expert_matching_data.location;
        this.edited_item.description = this.expert_matching_data.description;
        this.edited_item.start_date = this.expert_matching_data.start_date;
        this.edited_item.end_date = this.expert_matching_data.end_date;
        this.edited_item.expertise_detail =
          this.expert_matching_data.expertise_detail;
        let expertise_temp = new Array();
        this.expert_matching_data.expertise_detail.forEach((element) => {
          expertise_temp.push(element.id);
        });
        this.edited_item.expertise_detail = Object.assign([], expertise_temp);
      }
    },

    async onFinish() {
      //this.edited_item.expertise_detail.forEach(extractIdExpertise);
      this.checkEmptyField(
        this.edited_item,
        this.validation,
        false,
        null,
        this.inputType
      );
      if (!this.empty_field) {
        this.isLoading = true;
        var data = {
          title: this.edited_item.title,
          location: this.edited_item.location,
          description: this.edited_item.description,
          start_date: this.edited_item.start_date,
          end_date: this.edited_item.end_date,
          expertise_detail_id: this.edited_item.expertise_detail,
        };

        if (this.inputType == "New") {
          await this.expert_matching(data);
        } else {
          data = {
            ...data,
            id: this.$route.params.id,
          };
          await this.expert_matching_update(data);
        }

        if (this.$store.getters["expertmatching/getStatus"] == 1) {
          this.$router.push(
            this.$translate({ name: "Expert Matching Dashboard" })
          );
        } else {
          this.showSnackbar({
            type: "error",
            text: this.$t("general.addOrEditData"),
          });
        }
      }
      this.isLoading = false;
    },

    async onSignUpRequest() {
      await this.signUpExpertMatching({
        expert_matching_id: this.$route.params.id,
      });
    },

    cancelForm() {
      this.$router.push(
        this.$translate({
          name: "Expert Matching Dashboard",
        })
      );
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
